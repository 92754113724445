import React from 'react';
import {
  Layout,
  SEO,
  Contact,
  Hero,
  HeroHeading,
  MapImage,
  YellowLink,
} from '../components';
import { useGraphQL } from '../hooks';

function ContactPage() {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <HeroSection />
      <Contact subHeading="We are here to help" />
      <MapImage />
    </Layout>
  );
}

function HeroSection() {
  const {
    contactHeroImage,
    site: { siteMetadata },
  } = useGraphQL();
  return (
    <Hero bgImage={contactHeroImage.childImageSharp.fluid}>
      <HeroHeading
        heading={[
          'We would love ',
          'to help you ',
          'with your ',
          'electrical needs',
        ]}
      />
      <div className="mt-4">
        <YellowLink
          to={`tel:${siteMetadata.phone.split(' ').join('')}`}
          text="Call us today"
        />
      </div>
    </Hero>
  );
}

export default ContactPage;
